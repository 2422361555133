/* You can add global styles to this file, and also import other style files */
@import "./assets/tailwind.css";

@import '~@angular/cdk/overlay-prebuilt.css';

$color-primary-blue: #00A0FF;
$color-primary-red: #FF6483;

body {
  font-family: Arial, Helvetica, sans-serif !important;
}

img {
  max-width: 100%;
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.prose.prose-red {
  a {
    color: $color-primary-red !important;
  }
}

.prose.prose-blue {
  a {
    color: $color-primary-blue !important;
  }
}

.prose {
  // Better UX/UI without flash between changing routes
  min-height: 400px !important;

  h5 {
    font-weight: bold;
    margin-bottom: -1.25rem;
  }
}

.bg-primary-blue {
  background-color: $color-primary-blue;
}

.bg-primary-red {
  background-color: $color-primary-red;
}

.hover:bg-primary-blue {
  &:hover {
    background-color: $color-primary-blue;
  }
}

.hover-text-primary-blue:hover {
  color: $color-primary-blue;
}
